.company-info {
    
    /* background: #ffffff; */
    /* align-content: center; */
}

.company-info-title {
    animation-name: from-right, from-transparent;
    animation-duration: 1.3s;
    font-size: 160%;
}

.company-info-title-big {
    animation-name: from-right, from-transparent;
    animation-duration: 1.3s;
    /* font-size: 160%; */
}

.company-info-content {

    margin: 2vh 4% 5vh;
    margin-top: 0;

    /* border-top: var(--borderThickness) solid var(--accentColor); */
}

.company-info-content > h1 {
    /* text-align: center; */

    padding-bottom: 0.5rem;
}

.company-info-story {
    animation-name: from-left, from-transparent;
    animation-duration: 1.4s;

    
}

.company-info-story > p {
    
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    /* font-weight: normal; */
    /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 25%)); */
    
}

.company-info-story > pre {
    
    /* font-family: v; */
    
/* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal; */
    /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 25%)); */
}



@keyframes from-blur {
    from{filter: blur(5px);}
    to{filter: blur(0);}
}

@media screen and (max-width: 960px) {
    .company-info{
        padding: 1rem 0vw 2vh;
    }

    .hero-content-wrapper > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .background {
        min-height: 100%;
        width: 100%;
        /* height: auto; */
    }
}

/* Phone */
@media screen and (max-width: 768px) {

	.company-info-content {

		margin: 2vh .5vw 5vh;
	}

    .hero-content-wrapper > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-content-wrapper > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .background {
        min-height: 100%;
        width: 100%;
    }

    .contact-info li {
    
        float: none;
    }
}

/* Phone */
@media screen and (max-width: 750px) {

    .companystory-head-group {
        flex-direction: column !important;
    }

    .companystory-head-button {
        width: 50% !important;
    }
}