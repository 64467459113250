/* Cards */
.cards_item_title.recognition {
    color: var(--accentColor);
}

.cards_item_text.recognition {
    font-size: 110%;
}

.cards_item_img.recognition {
    width: auto;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
}

.cards_item_img.wide {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.cards_items_spread {
    /* max-width: 810px; */
}

.cards_item_pic_wrap.recognition {
    height: 100px;
    /* height: 100px; */
    padding-top: 0;
}

/* Header */
.navbar-logo {
    
  }