/* medpopacity */

/* Phone size */
@media screen and (max-width: 800px) {

    .medpwidth100 {
      width: 100% !important;
    }
  
    .medpopacity {
      opacity: 0.3;
    }
  }
  
