/* Text */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

.text-center-vertical {
	vertical-align: middle;
}

.text-big {
	font-size: 500% !important;
}

/* Text Color */
.text-white {
	color: white !important;
}

.text-white > b {
	color: white !important;
}

.text-black {
	color: black !important;
}

.text-gray {
	color: #41332d !important;
}

.text-accent {
	color: var(--accent) !important;
}

.text-accentDark {
	color: var(--accentDark) !important;
}

.text-accentDarker {
	color: var(--accentDarker) !important;
}

.text-accent2 {
	color: var(--accent2) !important;
}

.text-accent3 {
	color: var(--accent3) !important;
}

.text-accent4 {
	color: var(--accent4) !important;
}

.text-accent5 {
	color: var(--accent5) !important;
}

.text-accent5Dark {
	color: var(--accent5Darker) !important;
}

/* Backgrounds */
.bgr-accent {
	background-color: var(--accent) !important;
}

.bgr-accentLight {
	background-color: var(--accentLight) !important;
}

.bgr-accent5 {
	background-color: var(--accent5) !important;
}

/* Borders */
.border-accent {
	border-color: var(--accent) !important;
}

.border-accentDarker {
	border-color: var(--accentDark) !important;
}

.border-accent5 {
	border-color: var(--accent5) !important;
}

.border-accent5Darker {
	border-color: var(--accent5Darker) !important;
}

.border-accent5Lighter {
	border-color: var(--accent5Lighter) !important;
}

.instagram>i {
	border-radius: 30%;
	padding: 0px 7.5%;
	background: #f09433;
	background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
	background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
	background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.list-item {
    font-size: 18px;
}

.italic {
	font-style: italic;
}

.bold {
	/* font-weight: bold !important; */
	font-weight: 100 !important;
	color: #414141;
}

b {
	/* color: #8b8b8b; */
	font-weight: 100 !important;
	color: #414141;
}

.capital {
	text-transform: capitalize;
}

.upper-case {
	text-transform: uppercase;
}

.font-p {
	font-family: SegoeUI;
    font-weight: normal;
}

.font-p > * {
	font-family: SegoeUI;
    /* font-weight: normal; */
}



.text-background {
	position: absolute;
	opacity: 0.1;
	width: 50%;
	font-size: clamp(40px, 20vmin, 150px) !important;
	/* left: 1%; */
	top: 0;
	bottom: 0;

	height: 100%;
	/* line-height: 0; */
}

.quote-icon {
	font-size: 40px;
	float: left;
	margin: 2%;
	height: 100%;
}


a:link {
	color: var(--accent);
  }
  
  /* visited link */
  a:visited {
	color: var(--accent5);
  }

.flex-font-size {
	font-size: min(max(8px, 4vw), 16px) !important;
}

.font-size-flex-medium {
	font-size: min(max(18px, 2vw), 21px) !important;
}