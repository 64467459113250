.so-container-wrapper {
	
}

.so-container {
	width: 100%;
}

.so-content {
	width: 70vmin;
	margin: auto;
	display: flex;
display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
}

.social-icon-link {
    color: white;
    font-size: 24px;
    transition: 0.15s ease-in-out;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0));
}

.social-icon-link:hover {
    /* opacity: 0.5; */
    filter: drop-shadow(0px 0px 4px gray);
}