@include 'node_modules/.../normalize.css';
* {
  /* Global variables */
  --accentRGB: 169, 190, 151;
  --accent: rgb(169, 190, 151);
  --accentColor: rgb(169, 190, 151);
  --accentLight: rgb(192, 219, 169);
  --accentDark: rgb(147, 165, 131);
  --accentDarker: rgb(118, 131, 107);

  --accent2: rgb(235, 36, 39);
  --accent3: rgb(242, 144, 44);
  --accent4: rgb(248, 205, 49);
  --accent5: #c3a194;
  --accent5Darker: #a88b7f;
  --accent5Lighter: #ddb7a8;
  
  --textColor: #777777;

  /* Sizes */
  --phoneWidth: 960px;
  --maxWidth: 1440px;
  --maxWidthBig: 1920px;
  --minWidth: 360px;


  --borderThickness: 1px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cambria;
  font-weight: 700;

}

/* .body {
position: absolute;
width: 100vw;
} */

.page-wrapper {
  background-color: rgb(255, 255, 255);
  z-index: 1;
}


.page {
  /* color: #777777; */
  color: var(--textColor);
min-width: var(--minWidth);
  /* transition: 0.5s; */
  /* min-height: 100vh; */
}

.button-link:link { text-decoration: none; }
.button-link:visited { text-decoration: none; }
.button-link:hover { text-decoration: none; }
.button-link:active { text-decoration: none; }

/* Button */
.button {
  display: flex;
  display: -webkit-flex;
  padding: 12px 24px;
  /* margin: 2% 5%; */
  border-radius: 4px;
  background-color: var(--accent);
  color: aliceblue;
  /* border-radius: 10px; */
  text-align: center;
  transition: all 0.2s ease;

  text-decoration: none;

  

  animation-name: from-right, from-transparent;
  animation-duration: 1.4s;
}

.button:hover {
  cursor: pointer;
  color: white;
  background-color: var(--accentDarker);
}

.button > .button-text {
  transition: all 0.6s ease;
  position: relative;
  transform: translateX(6px);
}

.button:hover > .button-text {
  text-decoration: underline;
  position: relative;
  transform: translateX(-11px);
}

.button > .arrow {
  position: relative;
  right: 0px;
  opacity: 0;
}

.button:hover > .arrow {
  right: -10px;
  opacity: 1;
}

.button-text {
  font-size: larger;
}



.page-content {
  max-width: var(--maxWidth);
  /* min-width: var(--minWidth); */
  margin-left: auto;
  margin-right: auto;
  padding: 1vh 5vw;
}

/* Logo */
.logo {
  cursor: pointer;
  transition: ease-out 0.25s;
}

.logo:hover {
  transition: ease-out 0.25s;
  filter: drop-shadow(0px 0px 3px gray);
  /* var(--accent) */
}

/* Underline effect */
.un {
  display: inline-block;
}

.un::after {
  content: '';
  width: 0;
  height: 2.1px;
  display: block;
  background: #242424;
  transition: 0.3s;
}

.un:hover::after {
  width: 100%;
}

/* Generic Text */
h1 {
  
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 25%)); */
}

p {
  font-size: large;
}

.font-light {
  font-weight: 400;
}

.interactive-info {
  color: var(--accentDarker);
  transition: all 0.25s;
  text-decoration: none;
}

.interactive-info:hover {
  text-decoration: underline;
  color: white;
  filter: drop-shadow(0px 0px 3px gray);
}


hr.textUnderLine {
  border: 2px solid var(--accentColor);
  border-radius: 3px;

  max-width: 6rem;

  /* margin-left: auto; */
  margin-right: auto;

  margin-bottom: 0.5rem;
}

.fas {
  color: var(--accentColor);
}


.from-bottom-play {
  animation-name: from-bottom, from-transparent;
  animation-duration: 1.4s;
}

.from-left-play {
  animation-name: from-left, from-transparent;
  animation-duration: 1.4s;
}

/* Animations */
@keyframes from-left {
  from {
      transform: translateX(-19%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes from-right {
  from {
      transform: translateX(30%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes from-right-far {
  from {
      transform: translateX(70%);
  }
  to {
      transform: translateX(0);
  }
}

/* Animations */
@keyframes from-bottom {
  from {
      transform: translateY(30%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes from-top {
  from {
      transform: translateY(-30%);
  }
  to {
      transform: translateY(0);
  }
}


@keyframes from-transparent {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes to-transparent {
  from {
      opacity: 0.5;
  }
  to {
      opacity: 0.06;
  }
}

.text_underline {

  font-style: normal;

  text-decoration: underline;
  text-decoration-thickness: 2.5px;
  -webkit-text-decoration-color: var(--accent); /* Safari */
  text-decoration-color: var(--accent);
}

.text_bold {

  color: black;
  font-style: normal;
}

.shadow {
  filter: drop-shadow(0 6px 20px rgba(169, 190, 151, 0.74));
}

.shadow-under {
  /* filter: drop-shadow(0 6px 20px rgba(169, 190, 151, 0.74)); */
  box-shadow: 0 25px 17px -17px rgba(169, 190, 151, 0.74);
}

.shadow-thin {
  filter: drop-shadow(0 6px 20px rgba(169, 190, 151, 0.17));
}

.shadow-none {
  filter: drop-shadow(0 6px 20px rgba(169, 190, 151, 0));
}

.glow-white {
  filter: drop-shadow(0 0px 1.5px rgb(255, 255, 255));
}

.glow-black {
  filter: drop-shadow(0 0px 8px rgb(0, 0, 0));
}

.glow-accent {
  filter: drop-shadow(0 0px 8px var(--accent));
}

.glow-accent5 {
  filter: drop-shadow(0 0px 8px var(--accent5));
}

.shadow-dark {
  filter: drop-shadow(0 6px 400px rgba(59, 185, 235, 0.582));
}

.transparent {
  opacity: 0;
}

.blur {
  /* backdrop-filter: blur(2.2px); */
  background-color: rgba(255, 255, 255, 0.51);
}

.unavailable {
  background-color: #000000;
  opacity: 0.9;
  --color: rgba(195, 161, 148, 0.5);
  background: repeating-linear-gradient(-45deg, var(--color), var(--color) 5px, rgba(0,0,0,0.1) 5px, rgba(0,0,0,0.1) 25px);
  border-radius: 5px;
}

.unavailable2 {
  background-color: #000000;
  opacity: 0.9;
  --color: var(--accentDarker);
  background: repeating-linear-gradient(-45deg, var(--color), var(--color) 5px, rgba(0,0,0,0.1) 5px, rgba(0,0,0,0.1) 25px);
  border-radius: 5px;
  cursor: default !important;
}

.unavailable2 > a {
  cursor: default !important;
}

.unavailable > h2 {}

.round-edge {
  border-radius: 5px;
}

.round-edge-big {
  border-radius: 10px;
}

.width25 {
  width: 25% !important;
}
.width30 {
  width: 30% !important;
}
.width50 {
  width: 50% !important;
}
.width75 {
  width: 75% !important;
}
.width80 {
  width: 80% !important;
}

.width100 {
  width: 100% !important;
}

.widthMaxC {
  width: max-content !important;
}

.height50 {
  height: 50% !important;
}

.height100 {
  height: 100% !important;
}

.center-div {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.hide-horizontal {
  overflow-x: hidden;
}

.hide-vertical {
  overflow-y: hidden;
}

.center-div-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.center-div-horizontal {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.padding-left {
  padding-left: 5%;
}

.padding-horizontal {
  padding-left: 10px;
  padding-right: 10px;
}

/* Phone size */
@media screen and (max-width: 1000px) {
  .pwidth100 {
    width: 100% !important;
  }
  .pwidth50 {
    width: 50% !important;
  }

  .pheight50 {
    height: 50% !important;
  }
  .pheight100 {
    height: 100% !important;
  }
}

/* Phone size */
@media screen and (max-width: 800px) {

  .medpwidth100 {
    width: 100% !important;
  }

  .medpopacity {
    opacity: 0.3;
  }
}

/* Phone size */
@media screen and (max-width: 500px) {
  .button-text > p {
    font-size:medium !important;
  }

  .smallpwidth100 {
    width: 100% !important;
  }
}

