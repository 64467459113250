.column-wrapper {
    display: flex;
display: -webkit-flex;
    flex-direction: row;
    width: 100%;
}

.flex {
    display: flex;
}

.flex > ul {
    display: flex;
  flex-wrap: wrap;
}
.flex > ul > li {
    height: 40vh;
    flex-grow: 1;
}
.flex > ul > li > img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.row-wrapper {
    display: flex;
display: -webkit-flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.row {
    width: 50%;
}

.column {
    width: 100%;
}

.column-filler {
    width: 100%;
}

.column-right {
    float: right;
    text-align: right;
}

  /* Phone size */
  @media screen and (max-width: 600px) {
    .column-filler {
        width: 0;
    }
    
  }

.grid-2-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
.grid-2-3:last-child { grid-area: 2 / 1 / 3 / 3; }

.image-cover {
    object-fit: cover;
}