.container-group {
  display: flex;
display: -webkit-flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: stretch;
  /* margin: 'auto 100px'; */
  column-gap: 5%;
}

/* .container-group > .container-wrapper {
  width: 100%;
  flex-grow: 1;
} */

.container-group > .container-wrapper-grow {
  width: 100%;
  flex-grow: 1;
}

.container-wrapper {
  
  /* z-index: 2; */
  /* margin: auto 0; */

}

.container {
  width: 100%;

  overflow: hidden;

  margin: 15px auto;
}

.container-background {
	background-color: white;
  border-radius: 10px;

  /* box-shadow: 0 6px 20px rgba(59, 184, 235, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
  filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017)); */
}

.container-background-wide {
  border-radius: 10px;

  box-shadow: 0 6px 20px rgba(59, 184, 235, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
  filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
}

.container-content {
  padding: 2% 4%;
  height: 100%;
}

.container-content-list {
  display: flex;
display: -webkit-flex;
  flex-direction: column;
}

.container-image {
  /* position: absolute; */
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
 height: 200px;

}

.container-image > img {
  object-fit: cover;
 width: 100%;
 height: 100%;
}

.container-title {
  /* font-size: 250%; */
  /* font-size: 4vw; */
  animation-name: from-left, from-transparent;
  animation-duration: 1.4s;
}

/* mobile device */
@media only screen and (max-width: 850px) {
  .container-title {
    /* font-size: 250%; */
    /* font-size: 100%; */
    text-align: center;
  }

  .container-group {
    flex-direction: column;
    justify-content: space-evenly !important;
  }

  .container-group > .item{
    margin-top: 10%;
  }
}

/* bigger than mobile device */
@media only screen and (min-width: 850px) {
  
}
