.navbar-wrapper {
    position: sticky;
    width: 100%;
    z-index: 99999;
}

.navbar-title {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: .5%;
    background-color: rgb(0, 0, 0);
    color: rgb(74, 190, 103) !important;
}

.navbar-title > h3 {
    color: var(--accent) !important;
    font-size: 100%;
}

.navbar {
    background-color: var(--accent);
    width: 100%;
    min-height: 32px;
    z-index: 99999;
    transition: ease-out 0.25s;

}

.navbar-container {
  max-width: 1200px;

  height: var(--navbarHeight);
  
  font-size: 1.2rem;
    width: 100%;
    top: 0;
  display: flex;
  display: -webkit-flex;
  justify-content:flex-end;
  align-items: center;
  position: sticky;
  margin-left: auto;
    margin-right: auto;
}

.navbar-logo-container {
    height: 100%;
    margin-left: 2%;
    margin-right: 2%;
}

.a {
    display: flex;
    display: -webkit-flex;
}

.navbar-logo {
    color: #fff;
    padding: 5px;
    width: 80px;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu-wrapper {
    height: 100%;
    justify-self: end;
    margin-right: 2%;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-column-start: auto;

    /* display: flex; */
    /* flex-direction: row; */
    /* align-content: stretch; */

    gap: 10px;
    list-style: none;
    text-align: center;
    margin-right: 0.5vw;
    height: 100%;
    /* width: 100%; */
    margin-left: auto;
}

.nav-menu-secondary-wrapper {
  box-shadow: inset 0 3px 4px rgba(0,0,0,.19);
  /* background-color: #e9efe5; */
  background-color: #c2d7b8;
}


.nav-menu-secondary {
    max-width: 960px;
    padding: 3px 16px;

    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: flex-end;

    gap: 13px;
    list-style: none;
    text-align: center;
    height: 100%;
    /* width: 100%; */
    margin-right: auto;
    margin-left: auto;
}

.nav-item {
    height: var(--navbarHeight);
}

.nav-item-small {
    height: auto !important;
    justify-self: end;

}

.nav-item > a:link {
  color: white;
}

.nav-item-small > a:link {
  /* color: rgb(3, 3, 3); */
  /* font-weight: bold; */
}

.nav-item-small > .nav-links:hover {
  color: var(--accentDarker);
  border-bottom: 0px solid var(--accentDarker);

  transition: all 0.1s ease-out;
}
    
    /* visited link */
.nav-item > a:visited {
    color: white;
}

.nav-item-small > a:visited {
  color: white;
}

.nav-item-foldable {
  position: relative;
  cursor: pointer;
}

.foldable-menu-arrow {
  position: absolute;
  color: #fff !important;
  text-align: center;
  width: 100%;
  bottom: 10%;

  left: 0;
  right: 0;

  transform: rotate(0deg);
  transform: scaleX(-1);

  transition-property: transform, bottom;
  transition-duration: .45s;
}

.nav-links:hover .foldable-menu-arrow {
  color: var(--accentDarker) !important;
  bottom: 0;
}

/* Active */
.foldable-menu-arrow.active {
  color: var(--accentDarker) !important;
  bottom: 0;

  z-index: 10000;

  transform: rotate(180deg);
  transition-property: transform, opacity;
}

.foldable-menu-arrow.active-color {
  color: var(--accentDarker) !important;
}

.nav-item-foldable-item-list {
  list-style: none;

  transform-origin: top left;
  transform: scaleY(.45);

  opacity: 0;
  transition: all .5s;
}

.nav-item-foldable-item-list.active {
  opacity: 1;
  transform: scaleY(1);
}

.nav-item-foldable-item {
  background-color: var(--accent5);
  border-top: 2px solid var(--accent5Darker);
}

/* Open */
.nav-item-foldable-item.active {
  opacity: 1;
}

.nav-item-foldable-item:last-child {
  overflow: hidden;
  border-radius: 0px 0px 7px 7px;
}

.nav-links-foldable {
  display: flex;
  flex-direction: row;
}


.nav-links-active {
  color: var(--accentDarker) !important;
}

.nav-links {
    color: rgb(255, 255, 255);
    display: flex;
    display: -webkit-flex;
    text-decoration: none;
    padding: 0.5rem 1rem;

    height: 100%;
    border-bottom: 0px solid var(--accentDarker);
    font-family: FranklinGothicMediumRegular;
    /* , 'Arial Narrow', Arial, sans-serif */
    font-weight: 600;

    transition: all 0.2s ease-out;

    align-items: center;

}

.nav-links:hover {
    color: var(--accentDarker);
    border-bottom: 4px solid var(--accentDarker);

    transition: all 0.1s ease-out;
}

.nav-links-foldable.active {
    color: var(--accentDarker);
    border-bottom: 4px solid var(--accentDarker);

    transition: all 0.1s ease-out;
}



.accent {
    border-bottom: 4px solid var(--accent);
}

.nav-links-mobile {
    display: none;
}

/* Dont display the phone menu icon */
.menu-icon {
    display: none;
}

/* 

Phone size 

*/
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {

      display: flex;
      /* display: -webkit-flex; */
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: var(--navbarHeight);

      left: -100%;
      opacity: 1;
      justify-content: normal;

      transition: all 0.5s ease;
    }

    .nav-menu-secondary {
      padding: 7px 16px;
      
      gap: 30px;
    }

    /* @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      .nav-menu {
        background-color: rgba(255, 255, 255, .5);
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
      }
    } */
  
    .nav-menu.active {
      
      /* position: fixed; */
      background: rgba(var(--accentRGB), 0.651);
      left: 0;
      opacity: 1;
      z-index: 11111111;
      height: calc((100vh - var(--navbarHeight)) - 30px);
      transition: all 0.75s ease;
      /* overflow: scroll; */
    }

    .nav-menu-small.active {
      
      /* position: fixed; */
      background: none !important;
      left: 0;
      opacity: 1;
      z-index: 11111111;
      height: calc((100vh - var(--navbarHeight)) - 30px);
      transition: all 0.75s ease;
      /* overflow: scroll; */
    }

    .nav-item {
      height: auto;
    }
  
    .nav-links {
      background: #242222a6;
      text-align: center;
      padding: 8%;
      width: 100%;
      /* height: 100%; */
      display: table;
      color: rgb(255, 255, 255);

      /* border: 3px solid rgb(39, 39, 39); */

      border-bottom: 0px solid #fff;
    }

    .nav-item-small > .nav-links {
      background: #ffffff00 !important;
      text-align: center;
      padding: 8%;
      width: 100%;
      /* height: 100%; */
      display: table;
      color: rgb(255, 255, 255);

      /* border: 3px solid rgb(39, 39, 39); */

      border-bottom: 0px solid #fff;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424 !important;
      border-radius: 0;

      

      /* border-bottom: 0px solid #fff; */
    }

    .foldable-menu-arrow.active {
      /* Needs to be fixed */
      opacity: 0;

    }

    .nav-item-foldable-item-list {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    .accent {
      color: var(--accent);
    }

    .navbar-logo-container {
      height: 100%;
      margin-right: 5%;
    }  
  
    .navbar-logo {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0 2%;
      height: 100%;
      width: auto;
      filter: drop-shadow(0 0px 8px rgb(255, 255, 255));
    }

	/* .menu-icon-wrapper {

	} */
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;

      color: white;
      
      filter: drop-shadow(0px 0px 0px rgba(255,255,255,0));
      transition: 1.2s;
    }

    .menu-icon:hover {
      filter: drop-shadow(0px 0px 12px rgba(255,255,255,1));
      transition: 0.1s;
    }

    .fa-bars {
      color: #ffffff !important;
    }
  
    .fa-times {
      color: rgb(250, 250, 250) !important;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;

      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;

      transition: 250ms;
    }
  }

    /* bigger than mobile device */
	@media only screen and (min-width: 960px) {
		.nav-item {
			height: var(--navbarHeight);
		  }
	}