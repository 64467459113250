.footer-container {
    /* background-color: #1d1c1c; */
    background-color: var(--accent);


    padding: 0rem 0 1rem 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 0;
    /* height: 200px; */
}

.bgr {
	background-color: #ffffff;
}

.bgr-big {
	background-size: 16px 16px;
}



.footer-subscription {
    display: flex;
display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: white;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid white;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
display: -webkit-flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
display: -webkit-flex;
}

/* Link Items */

.footer-link-items {
    display: flex;
display: -webkit-flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: white;
}

.footer-link-items a {
    color: white;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
    text-decoration: underline;
}

.footer-logo {
    width: 70px;
}

/* Email */

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */

.social-icons {
    display: flex;
display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.footer-background-image-container {
    display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
    height: 100%;
}


.footer-background-image {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    z-index: -100000;
}
/* .footer-background-image {
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    z-index: -100000;
} */

.social-media {
    /* position: absolute; */
    margin-top: 1rem;
    max-width: 1000px;
    width: 100%;
    
}

.social-media-wrap {
    display: flex;
display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto 0 auto;
}

.social-logo {
    color: white;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
display: -webkit-flex;
    align-self: center;
    margin-bottom: 16px;
}

.website-rights {
    color: rgb(255, 255, 255);
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;

    }
}