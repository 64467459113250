

.cards_container.horizontal {
	/* max-height: 10%; */
}

.cards_items.horizontal {
	display: block;
}

.cards_item.horizontal {
	margin-bottom: 4%;
	
}

.cards_item_link.horizontal{
	display: grid;
	min-height: 0;
	height: 400px;
  	grid-template-columns: 1fr 1fr;
	
}

.cards_item_half.horizontal {
	display: block;
	height: 100%;
	min-height: 0;
}

.cards_item_info.horizontal {
	display: grid;
	grid-template-rows: 0.5fr 3fr 0.5fr;
	height: 100%;
	padding: 2% 4% 0;
}

.cards_item_title.horizontal {
	font-size: 150%;
	line-height: normal;
}

.cards_item_label.horizontal {
	font-size: 150%;
}

.cards_item_text.horizontal {
	overflow-y: scroll;
}

.cards_item_pic_wrap.horizontal {
	padding-top: 0;
  }

/* mobile device */
@media only screen and (max-width: 850px) {

}

/* bigger than mobile device */
@media only screen and (min-width: 850px) {

}