* {
    --navbarHeight: 80px;
}

hr {
    border: 1px solid rgb(226, 232, 236);
}

.border-left {
    border-left: 5px solid red;
    border-radius: 0 10px 10px 0 !important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.fit-content {
    width: fit-content !important;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

.margin-center-vertically {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.round {
    border-radius: 50%;
}

.aaa {
    display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.center-container {
    position: relative;
  }

.vertical-center {
    /* margin: 0; */
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.horizontal-center {
    /* margin: 0; */
    position: relative;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .center-element {
    /* margin: 0; */
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }