.header-media {
left: 0;
	top: 0;
	background-color: rgb(255, 255, 255);

	animation-name: from-transparent;
    animation-duration: 1.2s;
}

.slide-container > div {
	height: 100%;
}

.react-slideshow-container {
	height: 100%;
}

.react-slideshow-fadezoom-wrapper {
	height: 100%;
}

.react-slideshow-fadezoom-images-wrap {
	height: 100%;
}

.header-media-foreground {
	position: absolute;
    content:"";
    height:100%;
    width:100%;
    top:0;
    left:0;

	animation-name: from-right;
    animation-duration: 1.2s;

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0+10,1+30,1+100 */
background: -moz-linear-gradient(left,  hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 10%, hsla(0,0%,100%,1) 30%, hsla(0,0%,100%,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  hsla(0,0%,100%,0) 0%,hsla(0,0%,100%,0) 10%,hsla(0,0%,100%,1) 30%,hsla(0,0%,100%,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  hsla(0,0%,100%,0) 0%,hsla(0,0%,100%,0) 10%,hsla(0,0%,100%,1) 30%,hsla(0,0%,100%,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

}

.hero-media-wrapper {
    width: 100%;
}

.header-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
}

.header-image {
    /* position: relative; */

    object-fit: cover;
    width: 100%;
	height: 100%;
    position: absolute;

    top: 0;
	z-index: -100;
}

.header-image-bgr-wrapper {
    display: none;

}

.header-image-bgr {
    display: none;
    position: absolute;
    top: 0;
}


  /* Only at a certain screen size */
  @media screen and (min-width: 1440px) {

    .header-image-bgr-wrapper {
        display: block;
        position: fixed;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
    
        width: 100vw;
        height: 107% !important;
        z-index: -100;
    
    }
    
    .header-image-bgr {
        display: block;
        object-fit: cover;
        
        filter: blur(8px);
        -webkit-filter: blur(8px);

        width: 100%;
        height: 100% !important;
        opacity: 0.3;

        position: absolute;
        top: 0;
    }

}

.header-column-title {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
    /* color: #888888 !important; */
}

.header-column-image-wrapper {
    position: relative;
    height: 100%;
    width: 33%;
    overflow: hidden;

    animation-name: from-transparent;
    animation-duration: 1.8s;
}

.header-column-image {
    /* position: relative; */
    height: 100%;
    width: 100%;
    
    object-fit: cover;
    
    /* -webkit-transform: translate(0, -100%); */
    /* scale: 110%; */
    transform: translateY(-100%) scale(1.1);
}

.header-column-image-foreground {
    z-index: 10;
    position: relative;

    box-sizing:border-box;
    margin: 0;
    width: 100%;
    height: 100%;
    /* top: -100%; */

    -webkit-box-shadow: inset -150px 0px 57px -39px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset -150px 0px 57px -39px rgba(255, 255, 255, 1);
    box-shadow: inset -150px 0px 57px -39px rgba(255, 255, 255, 1);
}

.header-column-image-foreground-2 {
    z-index: 10;
    position: relative;
    /* top: -100%; */

    box-sizing:border-box;
    margin: 0;
    width: 100%;
    height: 120%;
    top: -10%;

    box-shadow: inset 0px 0px 40px 39px rgb(255, 255, 255);
}

.column-wrapper-header {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    z-index: 15;
}



.header-story {
    font-size: min(max(14px, 2vw), 21px);
    height: 100%;

    animation-name: from-right, from-transparent;
    animation-duration: 1.3s;
}

.header-namelist {
    font-size: min(max(16px, 3vw), 30px);
    height: 100%;
    

    animation-name: from-right-far, from-transparent;
    animation-duration: 1.7s;
}

.header-namelist-big {
    font-size: min(max(22px, 3vw), 38px);
}

.header-story-content {
    margin-left: auto;
    color: white;
    width: 100%;
    height: 100%;
    

    z-index: 10000;
}

.header-list {
    height: 100%;
    display: flex;
display: -webkit-flex;
    list-style: none;
    flex-direction: column;
    
    justify-content: space-between;
    padding: 0;
    margin: 0;

    padding-top: 4%;
}

.header-list > li {
    flex-grow: 1;
    /* height: 100%; */
    
}

.header-story > .header-list > li > .header-list-item{
    font-weight: 100;
    font-style: italic;
}

.header-list-item {
    line-height: 120%;
    margin-top: auto;
    margin-bottom: auto;
}



.header-story-content > p {
    font-size: larger;
    text-align: center;
    animation-timing-function: ease-out;
    animation-name: from-right;
    animation-duration: 1.3s;
    font-size: larger;
}

.header-story-content > h1 {
    color: #6992d4;
    animation-timing-function: ease-out;
    animation-name: from-right;
    animation-duration: 1.7s;
}

.header-story-content > h2 {
    color: rgb(180, 175, 147);
    
    animation-timing-function: ease-out;
    animation-name: from-right;
    animation-duration: 2.2s;
}

.slideshow-container {
overflow: hidden;

}

.slide-container {
	height: 100%;
    position: fixed;
	z-index: -100;
}

.slide-container.home {
}

.header-slideshow-wrapper {

}

.header-slideshow {
    object-fit: cover;

	height: 100%;
    
    
    max-width: 500px;

    position: fixed;
	z-index: -100;
}



.hero-content-wrapper > .header-logo {
    width: 85%;
	z-index: 1000;
    max-width: 830px;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 90%));
    animation-name: from-transparent;
    animation-duration: 1.2s;
}

.hero-container-wrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--maxWidth);
    /* top: 60px; */
    /* margin-top: 80px; */
    height: 100%;
}

.hero-container {
}

.hero-container-background-wrapper {

    width: 100%;
    height: 100%;
}

.hero-container-background {
    position: absolute;
    background-color: #064dc3;
    width: 100%;
    height: 100%;
}

.hero-content-wrapper {
    width: 100%;
    display: flex;
display: -webkit-flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;

	/* Clamp the width */
	max-width: var(--maxWidthBig);
	margin-left: auto;
	margin-right: auto;

    

    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0));
}

.hero-content {
    /* width: 95%; */
    width: 100%;
    height: 100%;
    max-width: var(--maxWidth);

    margin-top: auto;
    margin-bottom: auto;
}

.colored-background {
	background-color: black;
	width: 100vw;
	position: absolute;
	height: 100%;
	z-index: -1000;
}

.background {
    object-fit: cover;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    height: auto;

    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    filter: blur(1px);

    animation-name: from-blur;
    animation-duration: 2.5s;
}



@keyframes from-blur {
    from{filter: blur(5px);}
    to{filter: blur(1px);}
    /* 0.78px */
}

@keyframes from-transparent {
    from{opacity: 0;}
    to{opacity: 1.0;}
}



.hero-content-wrapper-title-wrapper {
    position: absolute;
    z-index: 10000;
    width: 100%;
    max-width: var(--maxWidth);
    height: 100%;
    overflow: hidden;

    padding: 0 5vw;
}

.hero-content-wrapper-title {
/* bottom: top; */
    color: white;
    font-size: 33px;
    padding-bottom: 1vw;
    margin: 0;

    width: 100%;

    overflow: hidden;

    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 50%));
}

.hero-content-wrapper-title.text-background {
    text-align: left;
}

.hero-content-wrapper-quote-wrapper {
    top: 0;
    /* position: absolute; */
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero-content-wrapper-quote {

    position: absolute;
    /* top: 0; */
    bottom: 0;

    /* color: #ffb355; */
    color: #ffffff;
    /* font-size: 1vmin; */
    /* padding-top: 10%; */
    padding-bottom: max(1.5vw, 1em, 2rem);
    margin: 0;
    width: 100%;
    /* height: 100%; */

    font-weight: 100;

    font-style: italic;
    

    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 100%));
}

.hero-content-wrapper > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

/* .contact-info {
    color: white;
}

.contact-info-wrapper {
    padding-top: 1.5rem;
} */

.contact-info li {
    font-size: 18px;

    float: left;
    display: block;
    color: white;
    padding: 0px 16px;
    text-decoration: none;

    filter: drop-shadow(0px 0px 2px black);
}

.contact-info li:last-child {
    float:none;
    text-align: center;
    padding-top: 30px;
}

.contact-info > i {
    color: var(--accent);
}

.contact-info a {
    filter: drop-shadow(0px 0px 2px black);
}

  /* Phone size */
  @media screen and (max-width: 600px) {
    .header-column-image-wrapper {
        height: 100%;
        width: 80%;
        /* overflow: hidden; */
    }

    .column-wrapper-header {    
        width: 100%;
        padding: 0 2%;
    }

    .header-story {
    
        background-color: rgba(255, 255, 255, 0.2);
        -webkit-box-shadow: 0px 0px 56px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 56px -15px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 56px -15px rgba(255, 255, 255, 0.8);
    
    }

    .hero-content-wrapper-quote {
        width: 75vw;
    }

    .header-column-image-foreground-2 {
        display: none;
    }
  }

@media screen and (max-width: 960px) {
    .hero-content-wrapper > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .background {
        min-height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .hero-content-wrapper > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-content-wrapper > p {
        font-size: 30px;
    }

    .hero-content-wrapper-title {
        position: absolute;
        right: 0;
        left: 0;
        font-size: clamp(10px, 10vmin, 30px);
        text-align: center;
    }

    .contact-info li:last-child {
        padding-top: 0px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .background {
        min-height: 100%;
        width: 100%;
    }

    .contact-info-wrapper > contact-info li {
    
        float: none;
    }

    .header-story-content {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    
    }
}