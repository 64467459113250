.cards {
  background: #fff;
}

.cards > h1 {
  text-align: center;

  /* border-top: var(--borderThickness) solid var(--accentColor); */
  max-width: var(--maxWidth);

  padding-top: 1rem;

  margin-left: auto;
  margin-right: auto;
}

.cards_container {
  max-width: var(--maxWidth);
  width: 100%;
  margin: 0 auto 5%;
}

.cards_container > h2 {
  text-align: center;
  font-weight: 200;
}

.cards_wrapper {
  position: relative;
  margin: 1rem 0;
  /* margin-bottom: 45px; */
}

.cards_items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5%;
  column-gap: 2%;
  margin-bottom: 4vh;
}

.cards_items_spread {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  
  /* display: flex;
display: -webkit-flex;
  flex-direction: column; */

  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* margin-bottom: 4vh; */
}

.cards_items_spread > .cards_item {
	margin: 4%;
  
}

.cards_item {
  /* display: flex;
display: -webkit-flex; */
  /* flex: 1; */
  /* margin: 0 .75vw; */
  /* margin: 0 2% 5%; */
  /* transform: translateX(-40%); */
  border-radius: 10px;
}

.cards_item_background {
  background-color: white;
}

.cards_item_link {
  width: 100%;  

  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards_item_link.link_background {
  box-shadow: 0 6px 20px rgba(59, 184, 235, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
  filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
}

.cards_item_pic_wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
/* min-height: 0; */

  height: 100%;
  /* max-height: 400px; */
}

.cards_item_label_wrap {
  position: absolute;
  /* bottom: 0; */
  top: 0;
  width: 100%;
  height: auto;
  /* background-color: rgba(var(--accent), 50%); */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

}

.cards_item_label {
  color: white;

  padding: 2% 4%;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards_item_img {
  /* position: absolute; */
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards_item_info {
  padding: 4% 4% 5%;
}

.cards_item_title {
  color: #252e48;
  font-size: 20px;
  line-height: 24px;
}

.cards_item_text {
  color: #626775;
  font-size: 16px;
  /* font-size: 11px; */
  /* padding-top: 10px; */
  /* line-height: 24px; */
  white-space: pre-line;
}

.cards_item_subText {
  margin-top: 2%;
  color: var(--accentColor);
  font-size: 14px;
  font-style:italic;

  white-space: pre-line;
}

.cards_item_logo-text {
	display: block;
	width: 50%;
	margin: 2% auto;
}

.cards_item_logo-image {
	position: absolute;
	z-index: 100;
	width: 30%;

	bottom: 0;
	left: 0;

	margin: 2% 4%;
	background-color: rgba(255, 255, 255, 0.35);
	padding: 2%;
	/* border-radius: 0 10px 0 0; */
	border-radius: 10px;
	filter: drop-shadow(0px 0px 5px rgb(0, 0, 0));
}

/* mobile device */
@media only screen and (max-width: 850px) {
  .cards_items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* margin-bottom: 4vh; */
  }

  .cards_items_spread {
    display: flex;
display: -webkit-flex;
    flex-direction: column;
    max-width: 410px;
  

    /* margin-bottom: 4vh; */
  }
}

/* bigger than mobile device */
@media only screen and (min-width: 850px) {
  .cards_items_spread {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  
    /* margin-bottom: 4vh; */
  }
}