.a-duration02 {
    animation-duration: 0.2s !important;
}
.a-duration10 {
    animation-duration: 1s !important;
}
.a-duration13 {
    animation-duration: 1.3s !important;
}
.a-duration20 {
    animation-duration: 2s !important;
}

.a-from-transparent {
    animation-name: from-transparent;
    animation-duration: 0.4s;
}

.a-to-transparent {
    animation-name: to-transparent;
    animation-duration: 0.4s;
}

.a-from-left {
    animation-name: from-left;
    animation-duration: 0.4s;
}

.a-from-bottom {
    animation-name: from-bottom;
    animation-duration: 0.4s;
}